$(document).ready(function () {
  "use strict";

  $('#hamburguer-menu').on('click', function () {
    $('.menu-content ').show();

    $('#menu').toggleClass('cross');
    let anim = null;
    if ($('#menu').hasClass('closed')) { // menu is closed
      $('#wrapper')
        .addClass('m-opened')
        .css({'transform': 'scale(0.95)'}).fadeOut(500);
    } else { // menu is opened
      $('#wrapper')
        .fadeIn(500)
        .css({'transform': 'scale(1)'});
    }

    $('#menu').animate(anim, 500, function () {
      $('#menu').toggleClass('closed opened');

      if ($('#menu').hasClass('closed')) {// menu is closed
        $('#menu').removeAttr('style');

        $('#wrapper')
          .removeAttr('style')
          .removeClass('m-opened');

        $('.nav li', $('#menu')).removeAttr('style');

      }
    });

    if ($('#logo', $('#menu')).is(':visible')) {
      $('#logo, #social').fadeOut(500);
    } else {
      $('#logo, #social').fadeIn(500);
    }
  });
});
